import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import ConsultarCodigoCidPage from "./pages/ConsultarCodigoCidPage";
import ConsultarCodigoSusPage from "./pages/ConsultarCodigoSusPage";
import ConsultarCodigoTussPage from "./pages/ConsultarCodigoTussPage";
import HomePage from "./pages/HomePage";
import IdadeGestacionalPelaDumPage from "./pages/IdadeGestacionalPelaDumPage";
import IdadeGestacionalPelaUsgPage from "./pages/IdadeGestacionalPelaUsgPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/consultar-codigo-cid' element={<ConsultarCodigoCidPage />} />
        <Route path='/consultar-codigo-sus' element={<ConsultarCodigoSusPage />} />
        <Route path='/consultar-codigo-tuss' element={<ConsultarCodigoTussPage />} />
        <Route path='/idade-gestacional-pela-dum' element={<IdadeGestacionalPelaDumPage />} />
        <Route path='/idade-gestacional-pela-usg' element={<IdadeGestacionalPelaUsgPage />} />
      </Routes>
    </Router>
  );
};

export default App;
