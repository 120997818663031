import { useState } from "react";
import InputComponent from "../components/InputComponent";
import LayoutBaseComponent from "../components/LayoutBaseComponent";
import TextComponent from "../components/TextComponent";

const IdadeGestacionalPelaUsgPage = () => {
  const [dataUsg, setDataUsg] = useState("");
  const [idadeGestacionalUsg, setIdadeGestacionalUsg] = useState("");
  const [dataParto, setDataParto] = useState("");
  const [idadeGestacional, setIdadeGestacional] = useState("");

  const calcularIdadeGestacional = () => {
    const usgDate = new Date(dataUsg);

    if (isNaN(usgDate.getTime())) {
      return;
    }

    const currentDate = new Date();

    if (usgDate > currentDate) {
      return;
    }

    const [usgWeeks, usgDays] = idadeGestacionalUsg.split(" ");
    const totalUsgDays = parseInt(usgWeeks) * 7 + parseInt(usgDays);

    const gestationalAgeDays =
      totalUsgDays + Math.floor((currentDate.getTime() - usgDate.getTime()) / (1000 * 60 * 60 * 24));

    const weeks = Math.floor(gestationalAgeDays / 7);
    const days = gestationalAgeDays % 7;

    setIdadeGestacional(`${weeks} semanas e ${days} dias`);
    usgDate.setDate(usgDate.getDate() + (280 - totalUsgDays));
    setDataParto(usgDate.toISOString().split("T")[0]);
  };

  const inputs = [
    {
      label: "Data Atual",
      type: "date",
      value: new Date().toISOString().split("T")[0],
      readOnly: true,
    },
    {
      label: "Data da USG",
      type: "date",
      value: dataUsg,
      onChange: setDataUsg,
      onBlur: calcularIdadeGestacional,
    },
    {
      label: "Idade Gestacional pela USG (ex: 10 3)",
      type: "text",
      value: idadeGestacionalUsg,
      onChange: setIdadeGestacionalUsg,
      onBlur: calcularIdadeGestacional,
    },
    {
      label: "Idade Gestacional Atual",
      type: "text",
      value: idadeGestacional,
      readOnly: true,
    },
    {
      label: "Data provável do parto",
      type: "date",
      value: dataParto,
      readOnly: true,
    },
  ];

  const description: string = `
  Ferramenta utilizada para calcular a idade gestacional atual (do dia da avaliação) e a data provável do parto
  através da idade gestacional determinada pela ultrassonografia (USG). Para esse cálculo, é utilizada a idade
  gestacional fornecida pela USG e a data em que a USG foi realizada.`;

  return (
    <LayoutBaseComponent>
      <h1 className='text-3xl font-bold text-left mb-4 p-4'>Idade Gestacional pela USG</h1>
      <div className='flex flex-col md:flex-row'>
        <div className='md:w-1/2 p-4 w-full'>
          {inputs.map(({ label, type, value, readOnly, onChange, onBlur }) => (
            <div className='relative mb-4'>
              <InputComponent
                type={type}
                label={label}
                value={value}
                readOnly={readOnly}
                onChange={onChange ? (e) => onChange(e.target.value) : undefined}
                onBlur={onBlur}
              />
            </div>
          ))}
        </div>
        <div className='md:w-1/2 p-4 w-full'>
          <TextComponent text={description} />
        </div>
      </div>
    </LayoutBaseComponent>
  );
};

export default IdadeGestacionalPelaUsgPage;
