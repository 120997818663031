import { Link } from "react-router-dom";
import LogoImage from "../images/logo.jpg";

const LogoComponent = () => {
  return (
    <div className='fixed top-4 left-4 z-50'>
      <Link to='/'>
        <div className='rounded-full overflow-hidden'>
          <img src={LogoImage} alt='Logo Medkobe' className='w-12 h-12 md:w-24 md:h-24' />
        </div>
      </Link>
    </div>
  );
};

export default LogoComponent;
