interface InputProps {
  label: string;
  type: string;
  value?: string;
  readOnly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputComponent: React.FC<InputProps> = (props) => {
  return (
    <div className='relative w-full'>
      <label className='font-bold absolute left-2 top-1'>{props.label}</label>
      <input
        type={props.type}
        className='w-full pt-6 pb-2 px-2 bg-white bg-opacity-90 border-4 border-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
        value={props.value}
        readOnly={props.readOnly}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </div>
  );
};

export default InputComponent;
