import { useState } from "react";
import InputComponent from "../components/InputComponent";
import LayoutBaseComponent from "../components/LayoutBaseComponent";
import MarketBadgesComponent from "../components/MarketBadgesComponent";
import TextBoxComponent from "../components/TextBoxComponent";
import TextComponent from "../components/TextComponent";

const IdadeGestacionalPelaDumPage = () => {
  const [dum, setDum] = useState("");
  const [dataParto, setDataParto] = useState("");
  const [idadeGestacional, setIdadeGestacional] = useState("");

  const calcularIdadeGestacional = () => {
    const dumDate = new Date(dum);

    if (isNaN(dumDate.getTime())) {
      return;
    }

    const currentDate = new Date();

    if (dumDate > currentDate) {
      return;
    }

    const gestationalAgeDays = Math.floor((currentDate.getTime() - dumDate.getTime()) / (1000 * 60 * 60 * 24));

    const weeks = Math.floor(gestationalAgeDays / 7);
    const days = gestationalAgeDays % 7;

    setIdadeGestacional(`${weeks} semanas e ${days} dias`);
    dumDate.setDate(dumDate.getDate() + 280);
    setDataParto(dumDate.toISOString().split("T")[0]);
  };

  const inputs = [
    {
      label: "Data Atual",
      type: "date",
      value: new Date().toISOString().split("T")[0],
      readOnly: true,
    },
    {
      label: "Data da última menstruação (DUM)",
      type: "date",
      value: dum,
      onChange: setDum,
      onBlur: calcularIdadeGestacional,
    },
    {
      label: "Idade Gestacional Atual",
      type: "text",
      value: idadeGestacional,
      readOnly: true,
    },
    {
      label: "Data provável do parto",
      type: "date",
      value: dataParto,
      readOnly: true,
    },
  ];

  const description: string = `
  Ferramenta utilizada para calcular a idade gestacional atual (do dia da avaliação)
  e a data provável do parto através da data da última menstruação. Deve ser considerado o
  primeiro dia da última menstruação. Para esse cálculo, é utilizada a soma de 280 dias da
  data da última menstruação que equivale a 40 semanas de idade gestacional.`;

  const title: string = "Idade Gestacional pela DUM";

  return (
    <LayoutBaseComponent>
      <h1 className='text-3xl font-bold text-left mb-4 p-4'>{title}</h1>
      <div className='flex flex-col md:flex-row'>
        <div className='md:w-1/2 p-4 w-full'>
          {inputs.map(({ label, type, value, readOnly, onChange, onBlur }) => (
            <div className='relative mb-4'>
              <InputComponent
                type={type}
                label={label}
                value={value}
                readOnly={readOnly}
                onChange={onChange ? (e) => onChange(e.target.value) : undefined}
                onBlur={onBlur}
              />
            </div>
          ))}
        </div>
        <div className='md:w-1/2 p-4 w-full'>
          <TextComponent text={description} />
        </div>
      </div>
    </LayoutBaseComponent>
  );
};

export default IdadeGestacionalPelaDumPage;
