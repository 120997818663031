import { Link } from "react-router-dom";
import LinkItem from "../types/LinkItem";

interface LinkListProps {
  linksData: LinkItem[];
}

const LinkListComponent: React.FC<LinkListProps> = (props) => {
  return (
    <ul className='text-lg font-bold' style={{ fontSize: "22px" }}>
      {props.linksData.map((link, index) => (
        <li className='p-4' key={index}>
          {link.to ? (
            <Link to={link.to}>{link.label}</Link>
          ) : (
            <>
              {link.label}
              {link.subLinks && (
                <ul>
                  {link.subLinks.map((subLink, subIndex) => (
                    <li className='py-1 px-10' key={subIndex}>
                      <Link to={subLink.to}>{subLink.label}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default LinkListComponent;
