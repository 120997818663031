import axios, { AxiosResponse } from "axios";
import Codigo from "../types/Codigo";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/codigos",
});

async function obterCodigosCid10(): Promise<Codigo[]> {
  const response: AxiosResponse<Codigo[]> = await api.get("/codigos-cid10");
  console.log(response);
  return response.data;
}

async function obterCodigosSus(): Promise<Codigo[]> {
  const response: AxiosResponse<Codigo[]> = await api.get("/codigos-sus");
  return response.data;
}

async function obterCodigosTuss(): Promise<Codigo[]> {
  const response: AxiosResponse<Codigo[]> = await api.get("/codigos-tuss");
  return response.data;
}

export { obterCodigosCid10, obterCodigosSus, obterCodigosTuss };
