import React, { useEffect, useState } from "react";
import LayoutBaseComponent from "../components/LayoutBaseComponent";
import MarketBadgesComponent from "../components/MarketBadgesComponent";
import TextBoxComponent from "../components/TextBoxComponent";
import InputComponent from "../components/InputComponent";
import { obterCodigosTuss } from "../api/codigosApi";
import Codigo from "../types/Codigo";

const CodigosTussPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dadosTuss, setDadosTuss] = useState<Codigo[]>([]);
  const [tussFiltrados, setTussFiltrados] = useState<Codigo[]>([]);

  useEffect(() => {
    const fetchDadosTuss = async () => {
      const fetchedDadosTuss: Codigo[] = await obterCodigosTuss();
      setDadosTuss(fetchedDadosTuss);
    };

    fetchDadosTuss();
  }, []);

  useEffect(() => {
    setTussFiltrados(filterTuss(dadosTuss));
  }, [searchTerm, dadosTuss]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const normalizarString = (str: string): string => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterTuss = (dadosTuss: Codigo[]): Codigo[] => {
    return dadosTuss.filter((tuss) => {
      const codigoNormalizado = normalizarString(tuss.codigo).toLowerCase();
      const descricaoNormalizada = normalizarString(
        tuss.descricao,
      ).toLowerCase();
      const termoNormalizado = normalizarString(searchTerm).toLowerCase();

      return (
        codigoNormalizado.includes(termoNormalizado) ||
        descricaoNormalizada.includes(termoNormalizado)
      );
    });
  };

  return (
    <>
      <LayoutBaseComponent background={false}>
        <div className="flex flex-col justify-center items-center ">
          <div className="w-full md:w-3/5 space-y-2">
            <div className="flex flex-col items-center">
              <InputComponent
                type="text"
                label="Consultar TUSS"
                readOnly={false}
                value={searchTerm}
                onChange={handleInputChange}
              />
              <div className="flex flex-col items-center h-80  w-full ">
                <div className="pt-12 flex flex-col items-center w-full">
                  <table className="table-fixed w-full rounded-md overflow-hidden bg-white bg-opacity-90 border-4 border-black">
                    <thead>
                      <tr>
                        <th className="w-1/4 p-2 border border-black">
                          Código
                        </th>
                        <th className="w-3/4 p-2 border border-black">
                          Descrição
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tussFiltrados.slice(0, 5).map((item, index) => (
                        <tr key={index}>
                          <td className="p-2 border border-black">
                            {item.codigo}
                          </td>
                          <td className="p-2 border border-black">
                            {item.descricao}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <TextBoxComponent text="Baixe já o MedKobe e acesse questões comentadas para residência médica!" />
            <MarketBadgesComponent />
            <TextBoxComponent
              text="Domine as provas de residência médica com nosso app: mais de 300 mil questões
                  comentadas e em áudio, com suporte de IA para aprimorar seu estudo. Baixe agora,
                  disponível para Android e iOS!"
            />
          </div>
        </div>
      </LayoutBaseComponent>
    </>
  );
};

export default CodigosTussPage;
