import React from "react";

// Images
import BackgroundDesktopImage from "../images/background-image-desktop.jpg";
import BackgroundMobileImage from "../images/background-image-mobile.jpg";

// Components
import LogoComponent from "./LogoComponent";

interface LayoutBaseProps {
  children: React.ReactNode;
  background?: boolean;
  text?: string;
}

const LayoutBaseComponent: React.FC<LayoutBaseProps> = ({ children, background = true }) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const backgroundStyle: React.CSSProperties = {
    backgroundImage: `url(${isMobile ? BackgroundMobileImage : BackgroundDesktopImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    pointerEvents: "none",
  };

  return (
    <div className='h-screen w-screen p-1 pt-20 md:p-1'>
      <div style={backgroundStyle}></div>

      <LogoComponent />

      {/* Principal */}
      <div className='w-full md:h-full flex items-center justify-center relative'>
        {background ? (
          <div className='w-full md:w-3/5 bg-white opacity-90 rounded-md shadow-md border-4 border-black'>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default LayoutBaseComponent;
