import LayoutBaseComponent from "../components/LayoutBaseComponent";
import LinkListComponent from "../components/LinkListComponent";
import LinkItem from "../types/LinkItem";

const HomePage = () => {
  const links: LinkItem[] = [
    {
      label: "Códigos",
      subLinks: [
        {
          label: "Consultar códigos CID",
          to: "/consultar-codigo-cid",
        },
        {
          label: "Consultar códigos SUS",
          to: "/consultar-codigo-sus",
        },
        {
          label: "Consultar códigos TUSS",
          to: "/consultar-codigo-tuss",
        },
      ],
    },
    {
      label: "Calculadoras Obstétricas",
      subLinks: [
        {
          label: "Idade Gestacional pela DUM",
          to: "/idade-gestacional-pela-dum",
        },
        {
          label: "Idade Gestacional pela USG",
          to: "/idade-gestacional-pela-usg",
        },
      ],
    },
    {
      label: "Clínica e Especialidade",
    },
  ];
  return (
    <>
      <LayoutBaseComponent>
        <LinkListComponent linksData={links} />
      </LayoutBaseComponent>
    </>
  );
};

export default HomePage;
