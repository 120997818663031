import GoogleBadgeImage from "../images/google-play-badge.png";
import AppleBadgeImage from "../images/app-store-badge.png";

const MarketBadgesComponent: React.FC = () => {
  return (
    <div className='flex justify-center space-x-4'>
      <a
        href='https://play.google.com/store/apps/details?id=br.com.medkobe&hl=pt_BR&gl=US'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={GoogleBadgeImage} alt='Disponível na Google Play' />
      </a>
      <a href='https://apps.apple.com/br/app/medkobe-ai-chatbot/id1668876471' target='_blank' rel='noopener noreferrer'>
        <img src={AppleBadgeImage} alt='Disponível na App Store' />
      </a>
    </div>
  );
};

export default MarketBadgesComponent;
