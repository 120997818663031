interface TextBoxProps {
  text: string;
}

const TextBoxComponent: React.FC<TextBoxProps> = (props) => {
  return (
    <div className='flex bg-black bg-opacity-90 text-white p-2 rounded-md w-full border-4 border-black font-bold text-[20px] text-center items-center'>
      {props.text}
    </div>
  );
};

export default TextBoxComponent;
